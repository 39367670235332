'use strict';

(function (window, document, Swiper, Inputmask, axios) {
  window.addEventListener('DOMContentLoaded', function() {
    menuMobile();
    formularioContato();
    produtosCarousel();
    produtos();

    Inputmask().mask(document.querySelectorAll("input[data-inputmask]"));
  });

  const produtosCarousel = () => {
    try {
      const carousel = document.querySelector('.produto-carousel');
      const hasItems = carousel.querySelectorAll('.produto-carousel__item').length >= 3;
      const carouselNextBtn = document.querySelector(".produto-carousel-button.swiper-button-next");
      const carouselPrevBtn = document.querySelector(".produto-carousel-button.swiper-button-prev");

      if (!hasItems) {
        carouselNextBtn.remove();
        carouselPrevBtn.remove();
        return;
      }

      new Swiper(carousel, {
        slidesPerView: 1,
        spaceBetween: 0,
        breakpoints: {
          576: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1440: {
            slidesPerView: 3,
            spaceBetween: 30,
          }
        },
        loop: true,
        navigation: {
          nextEl: carouselNextBtn,
          prevEl: carouselPrevBtn,
        },
        autoplay: {
          delay: 3000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        },
      });
    } catch {}
  };

  const formularioContato = () => {
    try {
      const form = document.getElementById('form-contato');

      form.addEventListener('submit',function(event) {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();

          const toast = new window.bootstrap.Toast(document.getElementById('form-response-error'));
          toast.show();
        } else {
          document.querySelectorAll('#form-contato .form-control').readonly = true;
          document.querySelector('#form-contato button[type="submit"]').disabled = true;
          document.querySelector('#form-contato button[type="submit"]').innerText = 'Enviando...';
        }

        form.classList.add('was-validated');
      }, false);
    } catch {}
  };

  const menuMobile = () => {
    try {
      const navLinks = document.querySelectorAll('.nav-link');
      const menu = document.getElementById('main-nav');
      const togglerBtn = document.querySelector('button.navbar-toggler');
      let bsCollapse = null;

      navLinks.forEach((l) => {
        l.addEventListener('click', () => {
          if (togglerBtn.clientHeight > 0) {
            bsCollapse = bsCollapse || new bootstrap.Collapse(menu);
            bsCollapse.toggle();
          }
        });
      });
    } catch {}
  };

  const produtos = () => {
    try {
      const modalProduto = document.getElementById('produto-detalhe');
      const modalProdutoBody = modalProduto.querySelector('.modal-body');
      const errorMessage = '<p class="error">Houve um erro na comunicação com o servidor. Tente novamente mais tarde.</p>';
      const loadingMessage = modalProdutoBody.innerHTML;

      modalProduto.addEventListener('show.bs.modal', event => {
        const button = event.relatedTarget;
        const url = button.getAttribute('href');

        axios({
          method: 'get',
          url,
          params: {
            ajax: '1',
          },
          headers: {
            'accept': 'text/html',
            'content-type': 'text/html',
            'X-Ajax': '1',
          }
        }).then(function (response) {
          modalProdutoBody.innerHTML = response.status === 200 ? response.data : errorMessage;
        }).catch(function () {
          modalProdutoBody.innerHTML = errorMessage;
        });
      });

      modalProduto.addEventListener('hidden.bs.modal', event => {
        modalProdutoBody.innerHTML = loadingMessage;
      });
    } catch {}
  };
})(window, window.document, window.Swiper, window.Inputmask, window.axios);
